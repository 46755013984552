import React, { useEffect, useState, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ToastPopup from '../popup/ToastPopup';
import { updatePassword } from '../Api/ListingApi';
import { navigate } from "gatsby"
import VisibilityOffSharpIcon from '@mui/icons-material/VisibilityOffSharp';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import { useLocation } from '@reach/router';

export default function NewPassWordForm() {
    const [loader, setLoader] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [passwordType1, setPasswordType1] = useState("password");
    const [success, setSucess] = useState(false);
    const [messages, setMessages] = useState("")
    const toggleSuccess = () => setSucess(p => !p);
    const inputElement = useRef(null);
    const pageParam = (useLocation().search?.split('=')[1]);

    useEffect(() => {
        if (inputElement.current) {
            inputElement.current.focus();
        }
    }, []);

    const DisplayingErrorMessagesSchema = Yup.object().shape({

        // email: Yup.string().email('Please Enter Valid Email id').required('Email ID is Required'),
        password: Yup.string().required('New Password is Required').min(8, 'Password must be 8 characters long').max(30, 'Password cannot exceed 30 characters'),
        // password: Yup.string().required("This field is required"),
        changepassword: Yup.string().required('Confirm Password is Required').min(8, 'Password must be 8 characters long').max(30, 'Password cannot exceed 30 characters').when("password", {
            is: val => (val && val.length > 0 ? true : false), then: Yup.string().oneOf([Yup.ref("password")], "Both password need to be the same"
            )
        })
    });

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    }

    const togglePassword1 = () => {
        if (passwordType1 === "password") {
            setPasswordType1("text");
            return;
        }
        setPasswordType1("password");
    }

    const handleSubmitFirebase = async (value) => {

        setLoader(true)
        let getParams = {
            "id": pageParam,
            "new_password": value.password,
            "confirm_password": value.changepassword
        }
        updatePassword(getParams).then((res) => {
            setLoader(false)
            if (res.result === 200) {
                setMessages(res.data.message)
                toggleSuccess()
                setTimeout(() => {
                    setLoader(false)

                    navigate("/signin");
                }, 1000)
            }
            setMessages(res.data.message)
            toggleSuccess()
            setTimeout(() => {
                setLoader(false)

                navigate("/signin");
            }, 1000)
        })

    }

    return (
        <div className='forget-formsection'>
            <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />
            {loader ? <Loader /> : null}
            <Formik
                initialValues={{
                    email: '',
                    password: "",
                    changepassword: ""
                }}
                validationSchema={DisplayingErrorMessagesSchema}
                onSubmit={values => handleSubmitFirebase(values)}>
                {({ values, handleChange, errors, touched, handleSubmit }) => (
                    <Form  >
                        {/* <Form.Group style={{ paddingBottom: "25px" }}>
                            <Form.Label className="form-field-label col-lg-2 col-xl-2 col-md-2 col-sm-2 col-form-label" style={{ fontSize: "18px", fontFamily: "'DejaVuSansBold'" }}>Email<span className='error-msg'> *</span></Form.Label>
                            <Form.Control name="email" value={values.email} onChange={handleChange('email')} className="form-control form-field SignUpForm SignUpForm0 InputErrorStyle SignUPFormagent" id="email" type='text' placeholder='Email Address' ref={inputElement} />
                            {touched.email && errors.email &&
                                <p className='error-msg mb-0 ErrorMessage1'>{errors.email}</p>
                            }
                        </Form.Group> */}

                        <Form.Group style={{ paddingBottom: "25px" }}>
                            <Form.Label className="form-field-label col-lg-5 col-xl-5 col-md-5 col-sm-5 col-form-label" style={{ fontSize: "18px", fontFamily: "'DejaVuSansBold'" }}>New Password<span className='error-msg'> *</span></Form.Label>
                            <div style={{ display: "flex" }}>
                                <Form.Control name="password" value={values.password} onChange={handleChange('password')} className="form-control form-field SignUpForm SignUpForm0 InputErrorStyle SignUPFormagent" id="email" type={passwordType} placeholder='New password' />
                                <p style={{ margin: "18px 0 0px -39px", cursor: "pointer" }} >
                                    {passwordType === "password" ? (
                                        <VisibilitySharpIcon onClick={togglePassword} />
                                    ) : (
                                        <VisibilityOffSharpIcon onClick={togglePassword} />
                                    )}
                                </p></div>
                            {touched.password && errors.password &&
                                <p className='error-msg mb-0 ErrorMessage1'>{errors.password}</p>
                            }
                        </Form.Group>

                        <Form.Group style={{ paddingBottom: "25px" }}>
                            <Form.Label className="form-field-label col-lg-6 col-xl-6 col-md-6 col-sm-6 col-form-label" style={{ fontSize: "18px", fontFamily: "'DejaVuSansBold'" }}>Confirm Password<span className='error-msg'> *</span></Form.Label>
                            <div style={{ display: "flex" }}>
                                <Form.Control name="changepassword" value={values.changepassword} onChange={handleChange('changepassword')} className="form-control form-field SignUpForm SignUpForm0 InputErrorStyle SignUPFormagent" id="email" type={passwordType1} placeholder='Confirm password' />
                                <p style={{ margin: "18px 0 0px -39px" }} >
                                    {passwordType1 === "password" ? (
                                        <VisibilitySharpIcon onClick={togglePassword1} />
                                    ) : (
                                        <VisibilityOffSharpIcon onClick={togglePassword1} />
                                    )}
                                </p></div>
                            {touched.changepassword && errors.changepassword &&
                                <p className='error-msg mb-0 ErrorMessage1'>{errors.changepassword}</p>
                            }
                        </Form.Group>
                        <Button onClick={handleSubmit} className='signup-submit-btn SignUpForm'>Submit</Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

